import React, { useState } from 'react';
import { AiOutlineClose } from "react-icons/ai";
import OneDriveFilePicker from "./OneDriveFilePicker";
import SharePointFilePicker from "./SharePointFilePicker";
import GoogleDriveFilePicker from "./GoogleDriveFilePicker";
import './cloudFilePicker.css';

const CloudFilePicker = ({ cloudService, onFileSelect, onClose }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState(null);

    const handleFileSelect = (files) => {
        console.log('Selected files:', files);
        setSelectedFiles(files);
        setError(null);
    };

    const handleAddFiles = () => {
        if (selectedFiles.length === 0) {
            setError("Please select at least one file.");
            return;
        }
        console.log('selectedFiles:', selectedFiles);
        const processedFiles = selectedFiles.map(file => ({
            id: file.id,
            name: file.name,
            webUrl: file.source === 'googledrive' ? file.webViewLink : file.webUrl,
            size: file.size,
            mimeType: file.mimeType,
            source: cloudService
        }));
        console.log('Processed files:', processedFiles);
        onFileSelect(processedFiles);
        onClose();
    };

    return (
        <div className="cloud-file-picker-overlay">
            <div className="cloud-file-picker-content">
                <button className="close-button" onClick={onClose}>
                    <AiOutlineClose className='big-x' />
                </button>
                <h2 className='cloud-service-header'>Select Files from {cloudService}</h2>
                <div className='cloud-service-container'>
                {cloudService === 'onedrive' && <OneDriveFilePicker onFileSelect={handleFileSelect} />}
                {cloudService === 'sharepoint' && <SharePointFilePicker onFileSelect={handleFileSelect} />}
                {cloudService === 'googledrive' && <GoogleDriveFilePicker onFileSelect={handleFileSelect} />}
                </div>
                {error && <p className="error-message">{error}</p>}

                <button onClick={handleAddFiles} disabled={selectedFiles.length === 0} className='add-selected-files-button'>
                    Add Selected Files ({selectedFiles.length})
                </button>
            </div>
        </div>
    );
};

export default CloudFilePicker;