import React, { useState, useCallback, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import LoadingSpinner from './assets/loadingSpinner';

const GoogleDriveFilePicker = ({ onFileSelect }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState(null);

    const login = useGoogleLogin({
        onSuccess: handleAuthSuccess,
        onError: handleAuthFailure,
        scope: 'https://www.googleapis.com/auth/drive.readonly',
    });

    const checkToken = useCallback(async (token) => {
        try {
            await axios.get('https://www.googleapis.com/drive/v3/files', {
                headers: { 'Authorization': `Bearer ${token}` },
                params: { pageSize: 1 }
            });
            return true;
        } catch (error) {
            console.error('Token validation failed:', error);
            return false;
        }
    }, []);

    const attemptAutoLogin = useCallback(async () => {
        console.log('Attempting auto-login');
        const token = localStorage.getItem('googleDriveToken');
        if (token) {
            console.log('Found stored token, validating...');
            const isValid = await checkToken(token);
            if (isValid) {
                console.log('Stored token is valid');
                setIsAuthenticated(true);
                return true;
            }
            console.log('Stored token is invalid');
        } else {
            console.log('No stored token found');
        }

        console.log('Launching Google login flow');
        login();
        return false;
    }, [checkToken, login]);

    useEffect(() => {
        setIsLoading(true);
        attemptAutoLogin();
    }, [attemptAutoLogin]);

    useEffect(() => {
        if (isAuthenticated) {
            console.log('User is authenticated, fetching files');
            fetchFiles();
        }
    }, [isAuthenticated]);

    function handleAuthSuccess(response) {
        console.log('Authentication successful');
        setIsAuthenticated(true);
        localStorage.setItem('googleDriveToken', response.access_token);
    }

    function handleAuthFailure(error) {
        console.error('Google Drive authentication failed:', error);
        setError('Failed to authenticate with Google Drive. Please try again.');
        setIsAuthenticated(false);
    }

    const fetchFiles = useCallback(async () => {
        if (!isAuthenticated) {
            console.log('Not authenticated, skipping file fetch');
            return;
        }
        setIsLoading(true);
        setError(null);
        try {
            const token = localStorage.getItem('googleDriveToken');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.get('https://www.googleapis.com/drive/v3/files', {
                headers: { 'Authorization': `Bearer ${token}` },
                params: {
                    q: "trashed = false",
                    fields: 'files(id,name,mimeType,size,webViewLink)',
                    pageSize: 500,
                },
            });
            console.log('Google Drive files fetched:', response.data.files.length);
            setFiles(response.data.files);
        } catch (error) {
            console.error('Error fetching Google Drive files:', error);
            setError('Failed to fetch files from Google Drive. Please try again.');
            setIsAuthenticated(false);
            attemptAutoLogin(); // Attempt to re-authenticate if fetching files fails
        } finally {
            setIsLoading(false);
        }
    }, [isAuthenticated, attemptAutoLogin]);

    const handleFileSelect = (file) => {
        file.webUrl = file.webViewLink;
        const isSelected = selectedFiles.some(f => f.id === file.id);
        if (isSelected) {
            setSelectedFiles(selectedFiles.filter(f => f.id !== file.id));
        } else {
            setSelectedFiles([...selectedFiles, file]);
        }
        onFileSelect(isSelected ? selectedFiles.filter(f => f.id !== file.id) : [...selectedFiles, file]);
    };

    return (
        <div className="google-drive-file-picker">
            {isLoading && <div className='loading-spinner-container'><LoadingSpinner/></div>}
            {!isLoading && isAuthenticated && (
                <ul className="file-list">
                    {files.filter(file => file.name.endsWith('.pdf')).map(file => (
                        <div key={file.id} className='cloud-file-select-card'>
                            <input
                                type="checkbox"
                                checked={selectedFiles.some(f => f.id === file.id)}
                                onChange={() => handleFileSelect(file)}
                            />
                            {file.name}
                        </div>
                    ))}
                </ul>
            )}
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default GoogleDriveFilePicker;