import React, { useState } from 'react';
import './semanticSearch.css';
import { FaSearch } from 'react-icons/fa';
import LoadingSpinner from '../components/assets/loadingSpinner';
import { getIdToken } from '../services/userDataService';
import { AiOutlineClose } from 'react-icons/ai';
import XLSXViewer from '../components/fileExplorer/xlsxViewer';


// Helper function to highlight search terms
const highlightSearchTerms = (text, searchTerm) => {
    try{
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        return text.split(regex).map((part, index) =>
            regex.test(part) ? <span key={index} className="highlight">{part}</span> : part
        );
    } catch (e) {
        console.log(e)
    }
};


// SemanticSearch component
const SemanticSearch = (userData) => {
    // State variables
    const [query, setQuery] = useState(''); // State for search query
    const [results, setResults] = useState([]); // State for search results
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [viewerUrl, setViewerUrl] = useState(null); // URL for the document viewer
    const [viewerType, setViewerType] = useState(null); // Type of document for the viewer
    const [error, setError] = useState(null); // Error state
    const api_url = process.env.REACT_APP_SEARCH_API_URL; // URL for the search API
    const searchURL = `${api_url}`; // URL for the search API

    // Handle the search form submission
    const handleSearch = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Set loading state to true before API call

        try {
            // Construct the URL with query parameters
            const url = new URL(searchURL);
            url.searchParams.append('query', query);
            console.log(userData.userData)

            const response = await fetch(`${api_url}?query=${query}&org_id=${userData.userData.user_data.org_id}&user_id=${userData.userData.user_data.user_id}&database=${userData.userData.vector_databases[0].database_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getIdToken(),
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch sessions');
            }

            const data = await response.json();
            console.log(data.search_results)
            setResults(data.search_results); // Assuming the API returns an object with a `results` array
        } catch (error) {
            console.error('Error fetching search results:', error);
            setResults([]); // Reset results on error
        }
        setIsLoading(false); // Set loading state to false after API call
    };

    const callViewFunction = async (url, documentType, page_number) => {
        console.log('Calling view function with:', { url, documentType });
        setError(null);
        try {
            const encodedUrl = encodeURIComponent(url);
            const response = await fetch(`${process.env.REACT_APP_INCLIENT_VIEWER}?file_url=${encodedUrl}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getIdToken()
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const parsed_data = await response.json();
            console.log('Parsed data:', parsed_data);

            if (parsed_data.presigned_url) {
                setViewerUrl(parsed_data.presigned_url+`#page=${page_number}`);
                setViewerType(documentType);
                console.log('Set viewer URL and type:', { url: parsed_data.presigned_url, type: documentType });
            } else {
                throw new Error('No URL found in the response');
            }
        } catch (error) {
            console.error('Error with fetch:', error);
            setError(error.message);
        }
    };

    const renderViewer = () => {
        console.log('Rendering viewer with:', { viewerUrl, viewerType });
        if (error) {
          return <div>Error: {error}</div>;
        }
        switch (viewerType) {
          case 'xlsx':
          case 'xls':
            return <XLSXViewer fileUrl={viewerUrl} />;
          case 'pdf':
          case 'docx':
          case 'doc':
          default:
            return (
              <iframe
                src={viewerUrl}
                title="Document Viewer"
                width="100%"
                height="100%"
                frameBorder="0"
                onLoad={() => console.log('iframe loaded')}
                onError={(e) => console.error('iframe error:', e)}
              />
            );
        }
      };

    return (
        <div className="SemanticSearch">
            <div className="search-bar-container">
                <form onSubmit={handleSearch} className="semantic-search-bar">
                    <input
                        type="text"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Enter search terms..."
                        className="search-input"
                    />
                    <button type="submit" disabled={isLoading} className="search-button">
                        <FaSearch className='search-icon' />
                    </button>
                </form>
            </div>
            <div className="search-results">
                {isLoading ? (
                    <div className='loader-container'>
                        <LoadingSpinner />
                    </div>
                ) : (
                    results.map((result, index) => (
                        <div key={index} className="result-item">
                            <div className="result-item-title">Document Title: {result.file_name}</div>
                            <h4>Uploaded Date: {result.upload_date}</h4>
                            <h3>Content:</h3>
                            <p>{highlightSearchTerms(result.text ? result.text : result.content , query)}</p>
                            <p>Page: {result.page_number}</p>
                            <p>From: {result.file_name}</p>
                            <button onClick={() => callViewFunction((result.url), result.file_type, result.page_number)}>View Document</button>
                        </div>
                    ))
                )}
            </div>
            {viewerUrl && (
                <div className="fullscreen-viewer">
                    <div className="viewer-header">
                        <button className="close-button" onClick={() => { setViewerUrl(null); setViewerType(null); setError(null); }}>
                            <AiOutlineClose />
                        </button>
                    </div>
                    {renderViewer()}
                    <div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SemanticSearch;
