import React, { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import process from "process";
import axios from 'axios';
import './upload.css';
import { AiOutlineFilePdf, AiOutlineCloudUpload, AiOutlineClose } from "react-icons/ai";
import { FaMicrosoft, FaGoogleDrive } from "react-icons/fa";
import { BsCloud } from "react-icons/bs";
import { getIdToken } from "../services/userDataService";
import { GoogleOAuthProvider } from '@react-oauth/google';
import FileInput from "../components/fileInput";
import FileCard from "../components/fileCard";
import CloudFilePicker from "../components/cloudFilePicker";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import LoadingSpinner from "../components/assets/loadingSpinner";
import { Buffer } from "buffer";
// import OneDriveFilePicker from "../components/OneDriveFilePicker";
// import SharePointFilePicker from "../components/SharePointFilePicker";
// import GoogleDriveFilePicker from "../components/GoogleDriveFilePicker";

const Upload = ({ userData }) => {
    const { instance, accounts } = useMsal();
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [error, setError] = useState(null);
    const [cloudUploadSource, setCloudUploadSource] = useState(null);
    const [isPersonalAccount, setIsPersonalAccount] = useState(false);
    const [showCloudPicker, setShowCloudPicker] = useState(false);
    const [selectedCloudService, setSelectedCloudService] = useState(null);
    const [source, setSource] = useState('w');
    const fileInputRef = useRef(null);


    const UPLOAD_LAMBDA_URL = process.env.REACT_APP_UPLOAD_LAMBDA_URL;
    const FETCH_HISTORY_URL = process.env.REACT_APP_FETCH_HISTORY_URL;

    useEffect(() => {
        if (accounts.length > 0) {
            // Check if it's a personal account
            // Personal accounts have a specific tenantId
            setIsPersonalAccount(accounts[0].tenantId === "9188040d-6c67-4c5b-b112-36a304b66dad");
        }
    }, [accounts]);


    const handleCloudUpload = (source) => {
        if (source === 'sharepoint' && isPersonalAccount) {
            setError("SharePoint is not available for personal Microsoft accounts. Please use OneDrive instead.");
        } else {
            setSelectedCloudService(source);
            setShowCloudPicker(true);
            setError(null);
        }
    };

    const handleCloudFileSelect = (files) => {
        const newFiles = files.map(file => ({
            file: {
                name: file.name,
                id: file.id,
                source: file.source,
                webUrl: file.webUrl,
                size: file.size,
                type: file.mimeType  // Changed from file.file.mimeType
            },
            metadata: { keywords: '', project: '' },
            customFields: []
        }));
        console.log('New files:', newFiles);
        setFilesToUpload(prevFiles => [...prevFiles, ...newFiles]);
        setShowCloudPicker(false);
        setSelectedCloudService(null);
    };

    useEffect(() => {
        fetchDocuments();
    }, [userData]);

    const fetchDocuments = async () => {
        if (!userData?.user_data?.user_id) return;
        try {
            const token = await getIdToken();
            const response = await axios.get(`${FETCH_HISTORY_URL}?user_id=${userData.user_data.user_id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            setDocuments(response.data || []);
        } catch (error) {
            console.error('Failed to fetch documents:', error);
            setError('Failed to fetch upload history. Please try again later.');
        }
    };

    const handleFileChange = (event) => {
        setSource('local');
        const newFiles = Array.from(event.target.files).map(file => ({
            file,
            metadata: { keywords: '', project: '' },
            customFields: []
        }));
        setFilesToUpload([...filesToUpload, ...newFiles]);
    };


    const updateMetadata = (index, field, value) => {
        const updatedFiles = [...filesToUpload];
        updatedFiles[index].metadata[field] = value;
        setFilesToUpload(updatedFiles);
    };

    const addCustomField = (index) => {
        const updatedFiles = [...filesToUpload];
        updatedFiles[index].customFields.push({ name: '', value: '' });
        setFilesToUpload(updatedFiles);
    };

    const updateCustomField = (fileIndex, fieldIndex, key, value) => {
        const updatedFiles = [...filesToUpload];
        updatedFiles[fileIndex].customFields[fieldIndex][key] = value;
        setFilesToUpload(updatedFiles);
    };

    const removeFile = (index) => {
        const updatedFiles = filesToUpload.filter((_, i) => i !== index);
        setFilesToUpload(updatedFiles);
    };

    const validateFileType = (file) => {
        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];

        // For cloud files, we check the type we assigned
        if (file.source) {
            return allowedTypes.includes(file.type);
        }

        // For local files, we use the original check
        return allowedTypes.includes(file.type);
    };

    const getOneDriveToken = async () => {
        const accounts = instance.getAllAccounts();
        if (accounts.length === 0) {
            throw new Error("No accounts logged in");
        }

        try {
            const oneDriveToken = await instance.acquireTokenSilent({
                scopes: ["Files.Read", "Files.Read.All"],
                account: accounts[0]
            });
            return oneDriveToken.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                const oneDriveToken = await instance.acquireTokenPopup({
                    scopes: ["Files.Read", "Files.Read.All"]
                });
                return oneDriveToken.accessToken;
            } else {
                throw error;
            }
        }
    };

    const removeCustomField = (fileIndex, fieldIndex) => {
        setFilesToUpload(prevFiles => {
            const newFiles = [...prevFiles];
            if (newFiles[fileIndex] && newFiles[fileIndex].customFields) {
                newFiles[fileIndex].customFields = newFiles[fileIndex].customFields.filter((_, i) => i !== fieldIndex);
                return newFiles;
            }
            return prevFiles;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError(null);

        if (!filesToUpload.length) {
            setError('Please select a file.');
            setIsLoading(false);
            return;
        }

        try {
            const token = await getIdToken();

            const uploadPromises = filesToUpload.map(async (fileObj) => {
                console.log('Uploading file:', fileObj);
                let data = {
                    fileName: fileObj.file.name,
                    userId: userData.user_data.user_id,
                    orgId: userData.user_data.org_id,
                    database: userData.vector_databases[0].database_id,
                    metadata: {
                        ...fileObj.metadata,
                        customFields: fileObj.customFields
                    }
                };


                if (source === 'local') {
                    // Handle local file upload
                    if (!(fileObj.file instanceof File)) {
                        throw new Error('Invalid file object for local upload');
                    }
                    const reader = new FileReader();
                    return new Promise((resolve, reject) => {
                        reader.onload = async () => {
                            const base64File = reader.result.split(',')[1];
                            data.file = base64File;
                            data.contentType = fileObj.file.type;
                            data.source = 'local';
                            try {
                                const response = await axios.post(UPLOAD_LAMBDA_URL, data, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    }
                                });
                                resolve(response.data);
                            } catch (error) {
                                reject(error);
                            }
                        };
                        reader.onerror = (error) => reject(error);
                        reader.readAsDataURL(fileObj.file);
                    });
                } else if (fileObj.file.source === 'onedrive') {
                    // Handle OneDrive file upload
                    data.fileId = fileObj.file.id;
                    // Use the @microsoft.graph.downloadUrl instead of webUrl
                    data.webUrl = fileObj.file.webUrl;
                    data.size = fileObj.file.size;
                    data.contentType = fileObj.file.type;
                    data.source = 'onedrive';

                    console.log(fileObj.file)
                    // Get OneDrive access token
                    const oneDriveToken = await getOneDriveToken();
                    data.oneDriveToken = oneDriveToken;

                    const file_url = await axios.get(`https://graph.microsoft.com/v1.0/me/drive/items/${fileObj.file.id}`, {
                        headers: {
                            'Authorization': `Bearer ${oneDriveToken}`,
                        },
                    });

                    const data_url = file_url.data['@microsoft.graph.downloadUrl'];

                    const response = await axios.get(data_url, {
                        responseType: 'blob',
                    });

                    const fileBlob = response.data;

                    // Read the file as base64
                    const reader = new FileReader();
                    reader.readAsDataURL(fileBlob);

                    // Wait for the file to be read
                    await new Promise((resolve) => {
                        reader.onloadend = resolve;
                    });

                    // Extract the base64-encoded file content
                    const base64File = reader.result.split(',')[1];

                    // Send the base64-encoded file in the request body
                    data.file = base64File;
                    data.source = 'local';

                    console.log('Uploading OneDrive file:', data);
                    return axios.post(UPLOAD_LAMBDA_URL, data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }
                    });
                } else if (fileObj.file.source === 'googledrive') {
                    const googleToken = localStorage.getItem('googleDriveToken');
                    const response = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileObj.file.id}?alt=media`, {
                        headers: { 'Authorization': `Bearer ${googleToken}` },
                        responseType: 'arraybuffer'
                    });
                    const base64File = Buffer.from(response.data, 'binary').toString('base64');
                    console.log('fileObj.file:', fileObj.file);
                    data.file = base64File;
                    data.contentType = fileObj.file.type;
                    data.source = 'googledrive';

                    console.log('Uploading Google Drive file:', data);
                    return axios.post(UPLOAD_LAMBDA_URL, data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }
                    });
                }
            });

            await Promise.all(uploadPromises);

            alert('Files uploaded successfully!');
            setFilesToUpload([]);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            fetchDocuments();
        } catch (error) {
            console.error('Upload failed:', error);
            setError('Failed to upload file(s). Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <GoogleOAuthProvider clientId={'44517948580-afu0cldpso7og3qbpfjlvui4csa8dun0.apps.googleusercontent.com'}>
            <div className="upload-page">
                <div className="upload-container">
                    <header className="header">
                        <h1>Upload</h1>
                        {filesToUpload.length > 0 &&
                            <div className="cloud-upload-options">
                                <FileInput onChange={handleFileChange} className='file-input' />
                                <button onClick={() => handleCloudUpload('onedrive')} className="cloud-button">
                                    <FaMicrosoft /> OneDrive
                                </button>
                                <button onClick={() => handleCloudUpload('sharepoint')} className="cloud-button">
                                    <BsCloud /> SharePoint
                                </button>
                                <button onClick={() => handleCloudUpload('googledrive')} className="cloud-button">
                                    <FaGoogleDrive /> Google Drive
                                </button>
                            </div>
                        }
                    </header>
                    <div id="drop-area">
                        {isLoading && <div className="big-loader"><LoadingSpinner className='loading-spinner' /></div>}
                        {!filesToUpload.length &&
                            <>
                                <p>Drag and drop files here or choose a cloud storage service</p>
                                <div className="cloud-upload-options">
                                    <FileInput onChange={handleFileChange} className='file-input' />
                                    <button onClick={() => handleCloudUpload('onedrive')} className="cloud-button">
                                        <FaMicrosoft /> OneDrive
                                    </button>
                                    <button onClick={() => handleCloudUpload('sharepoint')} className="cloud-button">
                                        <BsCloud /> SharePoint
                                    </button>
                                    <button onClick={() => handleCloudUpload('googledrive')} className="cloud-button">
                                        <FaGoogleDrive /> Google Drive
                                    </button>
                                </div>
                            </>
                        }
                        {/* File Cards for Review */}
                        <div className="file-cards">
                            {filesToUpload.map((fileObj, index) => (
                                <FileCard
                                    key={index}
                                    fileObj={fileObj}
                                    index={index}
                                    updateMetadata={updateMetadata}
                                    addCustomField={addCustomField}
                                    updateCustomField={updateCustomField}
                                    removeFile={removeFile}
                                    removeCustomField={removeCustomField}
                                    userData={userData}
                                />
                            ))}
                        </div>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    {showCloudPicker && (
                        <CloudFilePicker
                            cloudService={selectedCloudService}
                            onFileSelect={handleCloudFileSelect}
                            onClose={() => setShowCloudPicker(false)}
                        />
                    )}

                    {filesToUpload.length > 0 && (
                        <button onClick={handleSubmit} disabled={isLoading} className="upload-button">
                            <AiOutlineCloudUpload /> Upload Files
                        </button>
                    )}
                </div>
                <div className="upload-history">
                    <div className="upload-history-header">Upload History</div>
                    {documents.length === 0 ? <p>No files uploaded yet.</p> : (
                        documents.map((file, index) => (
                            <div key={index} className="document-history-card">
                                <div className="logo">
                                    <AiOutlineFilePdf />
                                </div>
                                <div className="details-section">
                                    <div className="document-history-card-header">
                                        {file.document_name}
                                    </div>
                                    <p className="date-added">{file.date_added}</p>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </GoogleOAuthProvider>
    );
}

export default Upload;