import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { Client } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";

const SharePointFilePicker = ({ onFileSelect }) => {
    const { instance, accounts } = useMsal();
    const [sites, setSites] = useState([]);
    const [currentSite, setCurrentSite] = useState(null);
    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [breadcrumb, setBreadcrumb] = useState([]);

    const graphScopes = ["Sites.Read.All", "Files.Read.All"];

    useEffect(() => {
        if (accounts.length > 0) {
            fetchSites();
        }
    }, [accounts]);

    const getAuthenticatedClient = async () => {
        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(instance, {
            account: accounts[0],
            scopes: graphScopes,
            interactionType: 'popup'
        });

        return Client.initWithMiddleware({ authProvider });
    };

    const fetchSites = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const client = await getAuthenticatedClient();
            const response = await client.api('/sites?search=*')
                .get();
            setSites(response.value);
        } catch (error) {
            setError("Failed to fetch SharePoint sites: " + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchFolderContents = async (siteId, folderId = null) => {
        setIsLoading(true);
        setError(null);
        try {
            const client = await getAuthenticatedClient();
            let endpoint = folderId 
                ? `/sites/${siteId}/drive/items/${folderId}/children`
                : `/sites/${siteId}/drive/root/children`;
            
            const response = await client.api(endpoint).get();
            
            setFolders(response.value.filter(item => item.folder));
            setFiles(response.value.filter(item => item.file));
            
            if (!folderId) {
                setCurrentSite(siteId);
                setBreadcrumb([{ id: null, name: "Root" }]);
            } else {
                setBreadcrumb(prevBreadcrumb => [
                    ...prevBreadcrumb,
                    { id: folderId, name: response.value.find(item => item.id === folderId)?.name || "Folder" }
                ]);
            }
        } catch (error) {
            setError("Failed to fetch folder contents: " + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSiteSelect = (siteId) => {
        setCurrentSite(siteId);
        fetchFolderContents(siteId);
    };

    const handleFolderSelect = (folderId) => {
        fetchFolderContents(currentSite, folderId);
    };

    const handleFileSelect = (file) => {
        setSelectedFiles(prevSelected => {
            const isAlreadySelected = prevSelected.some(f => f.id === file.id);
            if (isAlreadySelected) {
                return prevSelected.filter(f => f.id !== file.id);
            } else {
                return [...prevSelected, file];
            }
        });
    };

    const handleBreadcrumbClick = (index) => {
        const newBreadcrumb = breadcrumb.slice(0, index + 1);
        setBreadcrumb(newBreadcrumb);
        const lastItem = newBreadcrumb[newBreadcrumb.length - 1];
        fetchFolderContents(currentSite, lastItem.id);
    };

    const handleSubmit = () => {
        onFileSelect(selectedFiles);
    };

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="sharepoint-file-picker">
            {!currentSite ? (
                <>
                    <h2>Select a SharePoint Site</h2>
                    <ul>
                        {sites.map(site => (
                            <li key={site.id} onClick={() => handleSiteSelect(site.id)}>
                                {site.displayName}
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <>
                    <h2>Browse SharePoint Files</h2>
                    <div className="breadcrumb">
                        {breadcrumb.map((item, index) => (
                            <span key={item.id} onClick={() => handleBreadcrumbClick(index)}>
                                {item.name} {index < breadcrumb.length - 1 && " > "}
                            </span>
                        ))}
                    </div>
                    <h3>Folders</h3>
                    <ul>
                        {folders.map(folder => (
                            <li key={folder.id} onClick={() => handleFolderSelect(folder.id)}>
                                📁 {folder.name}
                            </li>
                        ))}
                    </ul>
                    <h3>Files</h3>
                    <ul>
                        {files.map(file => (
                            <li key={file.id}>
                                <input
                                    type="checkbox"
                                    checked={selectedFiles.some(f => f.id === file.id)}
                                    onChange={() => handleFileSelect(file)}
                                />
                                {file.name}
                            </li>
                        ))}
                    </ul>
                    <button onClick={handleSubmit} disabled={selectedFiles.length === 0}>
                        Upload Selected Files
                    </button>
                </>
            )}
        </div>
    );
};

export default SharePointFilePicker;