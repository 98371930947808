import React, { useRef } from 'react';

const FileInput = ({ onChange }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="file-input-wrapper">
      <button onClick={handleClick} className="choose-files-button">Choose Files</button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={onChange}
        accept=".pdf,.doc,.docx,.xlsx"
        multiple
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default FileInput;