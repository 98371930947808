import React from 'react';
import process from 'process';
import './userSettings.css';
import { AiOutlineGoogle, AiOutlineMail } from 'react-icons/ai';

const UserSettings = () => {
    const initiateOAuth = (provider) => {
        const clientId = provider === 'gmail'
            ? '44517948580-v78sv71ahej13lhm4n00c57ilt3116im.apps.googleusercontent.com'
            : process.env.REACT_APP_OUTLOOK_CLIENT_ID;
        const redirectUri = `${window.location.origin}/oauth-callback`;
        const scope = provider === 'gmail'
            ? 'https://www.googleapis.com/auth/gmail.readonly'
            : 'https://graph.microsoft.com/Mail.Read';
        const authUrl = provider === 'gmail'
            ? `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code&access_type=offline&prompt=consent`
            : `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;

        window.location.href = authUrl;
    };

    return (
        <div className="settings-main-area">
            <div className="page-header">User Settings</div>
            <div className="email-settings">
                <div className="email-settings-header">Email Integration</div>
                <div className="email-providers-list">
                    <div className='email-provider-item'>
                        <AiOutlineGoogle className='mail-icon' />
                        <button onClick={() => initiateOAuth('gmail')} className='settings-button'>
                            Connect Gmail
                        </button>
                    </div>
                    <div className='email-provider-item'>
                        <AiOutlineMail className='mail-icon' />
                        <button onClick={() => initiateOAuth('outlook')} className='settings-button'>
                            Connect Outlook
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserSettings;