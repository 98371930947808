import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { Client } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import LoadingSpinner from './assets/loadingSpinner';

const OneDriveFilePicker = ({ onFileSelect }) => {
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { instance, accounts } = useMsal();

    const loginRequest = {
        scopes: ["Files.Read", "Files.Read.All"]
    };

    useEffect(() => {
        if (accounts.length > 0) {
            fetchOneDriveFiles();
        } else {
            handleLogin();
        }
    }, [accounts]);

    const handleLogin = async () => {
        try {
            await instance.loginPopup(loginRequest);
        } catch (error) {
            console.error("Login failed", error);
            setError("Login failed. Please try again.");
        }
    };

    const getAuthenticatedClient = async () => {
        if (accounts.length === 0) {
            throw new Error("No accounts logged in");
        }

        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(instance, {
            account: accounts[0],
            scopes: loginRequest.scopes,
            interactionType: 'popup'
        });

        return Client.initWithMiddleware({ authProvider });
    };

    const fetchOneDriveFiles = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const client = await getAuthenticatedClient();
            const result = await client.api('/me/drive/root/children')
                .select('id,name,size,webUrl,file,@microsoft.graph.downloadUrl')
                .get();

            setFiles(result.value);
        } catch (error) {
            console.error("Error fetching files:", error);
            if (error instanceof InteractionRequiredAuthError) {
                // Token expired, need to re-authenticate
                await handleLogin();
                await fetchOneDriveFiles(); // Retry after login
            } else {
                setError("Failed to fetch files. Please try again.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileSelect = (file) => {
        const isSelected = selectedFiles.some(f => f.id === file.id);
        if (isSelected) {
            setSelectedFiles(selectedFiles.filter(f => f.id !== file.id));
        } else {
            setSelectedFiles([...selectedFiles, file]);
        }
        onFileSelect(isSelected ? selectedFiles.filter(f => f.id !== file.id) : [...selectedFiles, file]);
    };

    if (isLoading) {
        return <div className='loading-spinner-container'><LoadingSpinner/></div>;
    }

    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
                <button onClick={handleLogin}>Login to OneDrive</button>
            </div>
        );
    }

    if (accounts.length === 0 || files.length === 0) {
        return (
            <div>
                <p>Please login to access your OneDrive files.</p>
                <button onClick={handleLogin}>Login to OneDrive</button>
            </div>
        );
    }

    return (
        <div className="onedrive-file-picker">
            <ul className="file-list">
                {files.filter(file => file.name.endsWith('.pdf')).map(file => (
                    <div key={file.id} className='cloud-file-select-card'>
                        <input
                            type="checkbox"
                            checked={selectedFiles.some(f => f.id === file.id)}
                            onChange={() => handleFileSelect(file)}
                        />
                        {file.name}
                    </div>
                ))}
            </ul>
        </div>
    );
};

export default OneDriveFilePicker;