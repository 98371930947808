import React, { useState, useEffect, useRef } from "react";
import './chat.css';
import ArgusLogo from '../components/assets/Argus-dark.png';
import TypingIndicator from '../utils/typingIndicator';
import { generateUUID } from '../functions/sessions';
import fetchMessagesForSession from '../functions/fetchMessages';
import { currentAuthenticatedUser } from "../functions/authService";
import { IoMdSend, IoMdAdd, IoMdArrowBack } from "react-icons/io";
import ChatHistoryPanel from '../components/chatHistory';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import LoadingSpinner from '../components/assets/loadingSpinner';
import FilterPanel from "../components/filtersPanel";
import { getIdToken, getUserGroups } from "../services/userDataService";
import process from "process";
import axios from 'axios';

const Chat = ({ initialFilters, userData }) => {
    const [isHistoryLoading, setIsHistoryLoading] = useState(true);
    const [isChatLoading, setIsChatLoading] = useState(false);
    const [userSessions, setUserSessions] = useState([]);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const inputRef = useRef(null);
    const [streamingMessage, setStreamingMessage] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [chatTitle, setChatTitle] = useState('New Chat');
    const [sessionId, setSessionId] = useState(generateUUID());
    const [user, setUser] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [projects, setProjects] = useState([]);
    const [filters, setFilters] = useState({
        documents: [],
        documentType: [],
        project: [],
        fileType: [],
        keywords: [],
        uploadedOn: [],
        customInstructions: '',
        allDocumentsSelected: true
      });

    const LAMBDA_API_URL = process.env.REACT_APP_LAMBDA_API_URL;  
    const api_url = LAMBDA_API_URL;
    const FETCH_DOCUMENTS_URL = process.env.REACT_APP_FETCH_DOCUMENTS_URL;
    const SESSION_GETTER = process.env.REACT_APP_SESSION_GETTER;
    const UPDATE_CHAT_TITLES = process.env.REACT_APP_UPDATE_CHAT_TITLES;
    const GENERATE_SUMMARY = process.env.REACT_APP_GENERATE_SUMMARY;
    const FILE_EXPLORER_RETRIEVAL = process.env.REACT_APP_FILE_EXPLORER_RETRIEVAL;
    
    useEffect(() => {
        if (userData) {
            setUser(userData);
        }
        console.log('User:', userData);
    }, [userData]);

    const handleFilterChange = (newFilters) => {
        const allDocumentsSelected = newFilters.documents.length === 0;
        setFilters({
            ...newFilters,
            allDocumentsSelected
        });
        console.log('Filters:', {
            ...newFilters,
            allDocumentsSelected,
            documents: allDocumentsSelected ? 'All Documents' : newFilters.documents
        });
    };

    const fetchSessions = async (initial = false) => {
        if (!hasMore && !initial) return;

        setIsHistoryLoading(true);
        try {
            const userId = await currentAuthenticatedUser();
            const limit = 10;
            let url = `${SESSION_GETTER}?userId=${encodeURIComponent(userId)}&limit=${limit}`;

            if (lastEvaluatedKey && !initial) {
                url += `&lastEvaluatedKey=${encodeURIComponent(lastEvaluatedKey)}`;
            }

            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to fetch sessions');
            }
            const data = await response.json();
            setUserSessions(prevSessions => initial ? data.sessions : [...prevSessions, ...data.sessions]);
            setLastEvaluatedKey(data.lastEvaluatedKey);
            setHasMore(!!data.lastEvaluatedKey);
        } catch (error) {
            console.error('Error Fetching Sessions', error);
        } finally {
            setIsHistoryLoading(false);
        }
    };

    useEffect(() => {
        if (userData) {
            fetchDocuments();
            fetchProjects();
        }
    }, [userData]);

    useEffect(() => {
        fetchSessions(true);
    }, [userData]);

    const fetchProjects = async () => {
        try {
            const token = await getIdToken();
            const response = await axios.post(`${process.env.REACT_APP_FETCH_PROJECTS_URL}`,
                { 'org_id': userData.user_data.org_id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getIdToken(),
                    }
                });
            const fetchedProjects = response.data['projects'] || [];
            console.log('Fetched projects:', fetchedProjects);
            setProjects(fetchedProjects);
        } catch (error) {
            console.error('Failed to fetch projects:', error);
        }
    }

    const fetchDocuments = async () => {
        try {
            console.log('fetching documents');
            const token = await getIdToken();
            const response = await axios.get(`${FETCH_DOCUMENTS_URL}?org_id=${userData.user_data.org_id}&user_id=${userData.user_data.user_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                }
            });
            const fetchedDocuments = response.data || [];
            console.log('Fetched documents:', fetchedDocuments);
            setDocuments(fetchedDocuments);
            // Set initial filters with allDocumentsSelected as true
            setFilters(prevFilters => ({
                ...prevFilters,
                documents: [],
                allDocumentsSelected: true
            }));
        } catch (error) {
            console.error('Failed to fetch documents:', error);
        }
    }
    const handleLoadMore = () => {
        if (!isHistoryLoading) {
            fetchSessions();
        }
    };

    useEffect(() => {
        localStorage.setItem('sessionId', sessionId);
    }, [sessionId]);

    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleCardClick = async (sessionId) => {
        setIsChatLoading(true);
        try {
            const sessionMessages = await fetchMessagesForSession(sessionId);
            setSessionId(sessionId);
            setMessages(sessionMessages);
            const session = userSessions.find(s => s.sessionId === sessionId);
            setChatTitle(session ? session.title : 'Untitled Chat');
        } catch (error) {
            console.error('Error fetching messages:', error);
        } finally {
            setIsChatLoading(false);
        }
    }

    const resetFilters = () => {
        setFilters({
            documents: [],
            documentType: [],
            project: [],
            fileType: [],
            keywords: [],
            uploadedOn: [],
            customInstructions: '',
            allDocumentsSelected: true
        });
    };

    const handleNewChat = () => {
        setSessionId(generateUUID());
        setMessages([]);
        setChatTitle('New Chat');
        resetFilters();
    }

    const updateChatTitle = async (sessionId, newTitle) => {
        try {
            const response = await fetch(UPDATE_CHAT_TITLES, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'rename',
                    sessionId: sessionId,  // Ensure this is the correct sessionId
                    newTitle: newTitle
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update chat title');
            }

            const result = await response.json();

            setChatTitle(newTitle);
            // Update the title in userSessions state
            setUserSessions(prevSessions =>
                prevSessions.map(session =>
                    session.sessionId === sessionId ? { ...session, title: newTitle } : session
                )
            );
        } catch (error) {
            console.error('Error updating chat title:', error);
            // Handle error (e.g., show an error message to the user)
        }
    }

    const sendMessage = async (e) => {
        if (e) e.preventDefault();
        if (!input.trim()) return;
        const userId = await currentAuthenticatedUser();

        const userMessage = { id: Date.now(), text: input, sender: 'me' };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        setInput('');
        inputRef.current.style.height = 'auto';
        const database = userData?.vector_databases;
        console.log('database:', database);
        sendMessageToApi(input, sessionId, userId, database, filters)
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    async function sendMessageToApi(query, sessionId, userId, database, filters) {
        const apiUrl = `${api_url}`;
        setIsTyping(true);

        const preparedFilters = {
            ...filters,
            documents: filters.allDocumentsSelected ? documents.map(doc => doc.document_name) : filters.documents
          };
          delete preparedFilters.allDocumentsSelected;
        
          console.log('Sending message:', JSON.stringify({ 
            query, 
            sessionId, 
            userId, 
            database,
            filters: preparedFilters
          }));
        
          try {
            const response = await fetch(apiUrl, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': await getIdToken()
              },
              body: JSON.stringify({ 
                query, 
                sessionId, 
                userId, 
                database,
                filters: preparedFilters
              })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const answer = data['answer'];
            const sources = JSON.parse(data['source_documents']);
            console.log('sources:', sources);

            console.log('Answer:', data);

            setIsTyping(false);
            setMessages((prevMessages) => [...prevMessages, { id: Date.now() + Math.random(), text: answer, sender: 'bot', sources }]);
            setStreamingMessage(null);

            // Generate summary
            const summary_response = await fetch(GENERATE_SUMMARY, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': await getIdToken()
                },
                body: JSON.stringify({
                    sessionId: sessionId,
                    database: user?.vector_databases,
                    filters // Include filters in the summary request as well
                })
            });

            if (!summary_response.ok) {
                throw new Error(`HTTP error! status: ${summary_response.status}`);
            }

            return data;
        } catch (error) {
            console.error('Error with fetch:', error);
            setIsTyping(false);
            throw error;
        }
    }

    function autoGrowTextArea(event) {
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px`;
    }

    const togglePanel = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className="chat-page">
            <div className="chatAndFilters">
                <FilterPanel filters={filters} onFilterChange={handleFilterChange} documents={documents} projects={projects}/>
                <div className={`chatContainer ${isOpen ? 'reduced' : ''}`}>
                    <IoMdArrowBack onClick={togglePanel} className={`toggle-history ${isOpen ? 'reduced' : ''}`} />
                    {isChatLoading ? (
                        <div className="loading-spinner-container">
                            <LoadingSpinner />
                        </div>
                    ) : messages.length === 0 ? (
                        <div className="watermark">
                            <div className="watermark-container">
                                <img src={ArgusLogo} alt="Argus" className="main-watermark" />
                            </div>
                            <div className="description-text">
                                <p>Argus is your intelligent assistant. </p>
                                <p>Type a message to start a conversation and get assistance with your queries.</p>
                            </div>
                        </div>
                    ) : (
                        <div className="chatHistory">

                            {messages.map((message) => (
                                <div key={message.id} className={`message-${message.sender}`}>
                                    <ReactMarkdown
                                        className="message"
                                        children={message.text}
                                        remarkPlugins={[remarkBreaks]}
                                        rehypePlugins={[rehypeRaw]}
                                    />
                                    {message.sources && (
                                        <SourceList sources={message.sources} />
                                    )}
                                </div>
                            ))}
                            {streamingMessage && (
                                <div className="message-bot">
                                    <ReactMarkdown
                                        className="message"
                                        children={streamingMessage}
                                        remarkPlugins={[remarkBreaks]}
                                        rehypePlugins={[rehypeRaw]}
                                    />
                                </div>
                            )}
                            {isTyping && <TypingIndicator />}
                            <div ref={messagesEndRef} />
                        </div>
                    )}
                    <form className="messageForm" onSubmit={sendMessage}>
                        <textarea
                            ref={inputRef} // Attach ref to textarea
                            className="messageInput"
                            value={input}
                            onChange={(e) => {
                                setInput(e.target.value);
                                autoGrowTextArea(e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault(); // Prevents the default action of inserting a newline
                                    sendMessage(e); // Calls your sendMessage handler
                                }
                            }}
                            placeholder="Type a message..."
                        />
                        <div className="buttonContainer">
                            <IoMdSend
                                type="submit"
                                className="send"
                                onClick={sendMessage} />
                            <IoMdAdd
                                type="button"
                                className="newChat"
                                onClick={handleNewChat} />
                        </div>
                    </form>
                </div>
            </div>
            <ChatHistoryPanel
                history={userSessions}
                onCardClick={handleCardClick}
                onRename={updateChatTitle}
                onLoadMore={handleLoadMore}
                isLoading={isHistoryLoading}
                hasMore={hasMore}
                isOpen={isOpen}
                userIdFunc={currentAuthenticatedUser()}
                userData = {userData}
            />
        </div>
    );
}

const SourceList = ({ sources }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    }

    return (
        <div className="source-list">
            <button onClick={toggleExpanded} className="source-button">
                {isExpanded ? 'Hide Sources' : 'View Sources'}
            </button>
            {isExpanded && (
                <>
                    <ul>
                        {sources.map((source, index) => (
                            <li key={index}>
                                {source.document.file_name}, Page {source.document.page_number}
                            </li>
                        ))}
                    </ul>
                    <button onClick={toggleExpanded} className="source-button">Hide Sources</button>
                </>
            )}
        </div>
    );
};

export default Chat;
