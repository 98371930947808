import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './fileExplorerR2.css';
import { AiOutlineDelete, AiOutlineFilePdf, AiOutlineClose, AiOutlineShareAlt, AiOutlineSearch, AiOutlineEdit, AiOutlineCheck, AiOutlineArrowLeft, AiFillFolder, AiOutlineFolderAdd } from 'react-icons/ai';
import { FaFolder, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import LoadingSpinner from '../assets/loadingSpinner';
import XLSXViewer from './xlsxViewer';
import { getIdToken } from '../../services/userDataService';
import SharePopup from './sharePopup';
import ProjectSelector from '../projectSelector';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const renderDocumentItem = (document, onShare, onEdit, onDelete, onView, editingDocId, editedProject, onProjectSelect, userData) => (
  <div className="document-item">
    <div className='pdf-file-icon'>
      <AiOutlineFilePdf />
    </div>
    <div className='main-info'>
      <div className='docName'>{document.document_name}</div>
      <div className='docAdded'>{document.date_added}</div>
    </div>
    <div className='right-side-items'>
      {editingDocId === document.document_uuid ? (
        <ProjectSelector
          userData={userData}
          onProjectSelect={onProjectSelect}
          currentProject={editedProject}
        />
      ) : (
        <div className='project'>
          {document.project_name || 'No Project'}
        </div>
      )}
      <div className='status'>
        <div className='docStatus'>{document.status}</div>
        <div className='docSource'>{document.source}</div>
      </div>
      {document.url !== "none" && document.document_type.toLowerCase() === 'pdf' && (
        <button className='view-button' onClick={() => onView(document.url)}>View</button>
      )}
      <button className='share-button' onClick={() => onShare(document)}>Share</button>
      <AiOutlineDelete className='delete-icon' onClick={() => onDelete(document)} />
      {editingDocId === document.document_uuid ? (
        <AiOutlineCheck className='save-icon' onClick={() => onEdit(document, true)} />
      ) : (
        <AiOutlineEdit className='edit-icon' onClick={() => onEdit(document)} />
      )}
    </div>
  </div>
);


const DraggableItem = ({ item, type, onDrop, onFolderClick, onShare, onEdit, onDelete, onView, editingDocId, editedProject, onProjectSelect, userData }) => {
  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: { id: item.id || item.document_uuid, type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: ['folder', 'file'],
    drop: (droppedItem) => onDrop(droppedItem.id, item.id, droppedItem.type),
    canDrop: (droppedItem) => droppedItem.id !== item.id && type === 'folder',
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === 'folder') {
      onFolderClick(item.id);
    }
  };

  const renderContent = () => {
    if (type === 'folder') {
      return (
        <div className="file-folder-item" onClick={handleClick}>
          <AiFillFolder className="folder-icon" />
          <span>{item.name}</span>
        </div>
      );
    } else {
      return renderDocumentItem(item, onShare, onEdit, onDelete, onView, editingDocId, editedProject, onProjectSelect, userData);
    }
  };

  return (
    <div ref={drop}>
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className={`draggable-item ${type.toLowerCase()}-item ${isOver ? 'item-over' : ''}`}
      >
        {renderContent()}
      </div>
    </div>
  );
};


const FileExplorer = ({ userId, userData }) => {
  const [documents, setDocuments] = useState([]);
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([{ id: null, name: 'Home' }]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewerUrl, setViewerUrl] = useState(null);
  const [viewerType, setViewerType] = useState(null);
  const [error, setError] = useState(null);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [sharingDocument, setSharingDocument] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('document_name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [editingDocId, setEditingDocId] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [editedProject, setEditedProject] = useState('');
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  
  
  

  const FILE_EXPLORER_RETRIEVAL = process.env.REACT_APP_FILE_EXPLORER_RETRIEVAL;
  const ADD_NEW_FOLDER = process.env.REACT_APP_ADD_NEW_FOLDER;
  const UPDATE_PARENT_FOLDER = process.env.REACT_APP_UPDATE_PARENT_FOLDER;
  const UPDATE_DOCUMENT = process.env.REACT_APP_UPDATE_DOCUMENT;
  const SHARE_DOCUMENT = process.env.REACT_APP_SHARE_DOCUMENT;
  const INCLIENT_VIEWER = process.env.REACT_APP_INCLIENT_VIEWER;


  const handleCreateNewFolder = async () => {
    if (!newFolderName.trim()) {
      alert('Please enter a folder name');
      return;
    }

    try {
      const response = await fetch(ADD_NEW_FOLDER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': await getIdToken()
        },
        body: JSON.stringify({
          folderName: newFolderName,
          parentFolderId: currentFolder,
          userId: userData.user_data.user_id,
          orgId: userData.user_data.org_id,
          path: breadcrumbs.map(crumb => crumb.name).join('/') + '/' + newFolderName
        })
      });

      if (response.ok) {
        const data = await response.json();
        console.log('New folder created:', data);
        setIsNewFolderModalOpen(false);
        setNewFolderName('');
        fetchDocumentsAndFolders(); // Refresh the view
      } else {
        throw new Error('Failed to create new folder');
      }
    } catch (error) {
      console.error('Error creating new folder:', error);
      alert('Failed to create new folder. Please try again.');
    }
  };

  const renderNewFolderModal = () => (
    <div className="new-folder-modal">
      <h2>Create New Folder</h2>
      <input
        type="text"
        value={newFolderName}
        onChange={(e) => setNewFolderName(e.target.value)}
        placeholder="Enter folder name"
      />
      <button onClick={handleCreateNewFolder}>Create</button>
      <button onClick={() => setIsNewFolderModalOpen(false)}>Cancel</button>
    </div>
  );

  const handleDrop = async (draggedId, targetId, draggedType) => {
    if (draggedId === targetId) return;

    console.log('Updating structure with:', { draggedId, targetId, draggedType });

    try {
      const response = await fetch(UPDATE_PARENT_FOLDER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': await getIdToken()
        },
        body: JSON.stringify({
          draggedId,
          targetId,
          draggedType,
          userId: userData.user_data.user_id,
        })
      });

      if (response.ok) {
        // Refresh the view
        fetchDocumentsAndFolders();
      } else {
        console.error('Failed to update structure');
      }
    } catch (error) {
      console.error('Error updating structure:', error);
    }
  };



  const handleEditClick = (document) => {
    console.log('Editing document:', document);
    console.log('User Data:', userData);
    setEditingDocId(document.document_uuid);
    setEditedName(document.document_name);
    setEditedProject(document.project || '');
  };

  const handleBackClick = () => {
    if (breadcrumbs.length > 1) {
      const newBreadcrumbs = breadcrumbs.slice(0, -1);
      setBreadcrumbs(newBreadcrumbs);
      setCurrentFolder(newBreadcrumbs[newBreadcrumbs.length - 1].id);
    }
  };

  const handleCancelEdit = () => {
    setEditingDocId(null);
    setEditedName('');
    setEditedProject('');
  };

  const handleSaveEdit = async (document) => {
    try {
      const response = await fetch(UPDATE_DOCUMENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': await getIdToken()
        },
        body: JSON.stringify({
          document_id: document.document_uuid,
          org_id: document.org_id,
          project_id: editedProject,
          vector_database: userData.vector_databases[0].database_id,
        })
      });

      if (response.ok) {
        const updatedDoc = await response.json();
        setDocuments(prevDocs =>
          prevDocs.map(doc =>
            doc.document_uuid === updatedDoc.document_uuid ? { ...doc, ...updatedDoc } : doc
          )
        );
        setEditingDocId(null);
      } else {
        console.error('Failed to update document');
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const handleProjectSelect = (projectId) => {
    setEditedProject(projectId);
  };


  const searchDocuments = (docs) => {
    return docs.filter(doc =>
      doc.document_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.document_type.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const sortDocuments = (docs) => {
    return docs.sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const filteredAndSortedDocuments = sortDocuments(searchDocuments(documents));

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const renderSortIcon = (field) => {
    if (sortField !== field) return <FaSort />;
    return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  const handleShare = (document) => {
    setSharingDocument(document);
    setIsSharePopupOpen(true);
  };

  const handleShareComplete = async (document, selectedUsers) => {
    try {
      const response = await fetch(SHARE_DOCUMENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': await getIdToken()
        },
        body: JSON.stringify({
          documentId: document.id,
          sharedWith: selectedUsers.map(user => user.id)
        })
      });
      const data = await response.json();
      if (data.success) {
        // Optionally update the local state to reflect the new sharing status
        fetchDocumentsAndFolders();
      }

      setIsSharePopupOpen(false);
      setSharingDocument(null);
    } catch (error) {
      console.error('Error sharing document:', error);
    } finally {
      setSharingDocument(null);
    }
  };


  const callViewFunction = async (url, documentType) => {
    console.log('Calling view function with:', { url, documentType });
    setError(null);
    try {
      const encodedUrl = encodeURIComponent(url);
      const response = await fetch(`${INCLIENT_VIEWER}?file_url=${encodedUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': await getIdToken()
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const parsed_data = await response.json();
      console.log('Parsed data:', parsed_data);

      if (parsed_data.presigned_url) {
        setViewerUrl(parsed_data.presigned_url);
        setViewerType(documentType);
        console.log('Set viewer URL and type:', { url: parsed_data.presigned_url, type: documentType });
      } else {
        throw new Error('No URL found in the response');
      }
    } catch (error) {
      console.error('Error with fetch:', error);
      setError(error.message);
    }
  };

  const fetchDocumentsAndFolders = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(FILE_EXPLORER_RETRIEVAL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': await getIdToken()
        },
        body: JSON.stringify({ userId, folderId: currentFolder })
      });
      const data = await response.json();
      console.log('Fetched data:', data);
      setDocuments(data.documents);
      setFolders(data.folders);
      updateBreadcrumbs(data.currentFolderId, data.isRoot);
    } catch (error) {
      console.error('Error fetching documents and folders:', error);
      setError('Failed to fetch documents and folders');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDocumentsAndFolders();
  }, [currentFolder]);

  const handleFolderClick = (folderId) => {
    setCurrentFolder(folderId);
    // Remove updateBreadcrumbs call from here, it will be handled in the useEffect
  };

  const updateBreadcrumbs = (folderId, isRoot) => {
    if (isRoot) {
      setBreadcrumbs([{ id: null, name: 'Home' }]);
    } else {
      const currentFolder = folders.find(folder => folder.id === folderId);
      if (currentFolder) {
        setBreadcrumbs(prev => {
          const existingIndex = prev.findIndex(crumb => crumb.id === folderId);
          if (existingIndex !== -1) {
            return prev.slice(0, existingIndex + 1);
          } else {
            return [...prev, { id: folderId, name: currentFolder.name }];
          }
        });
      }
    }
  };

  const renderBreadcrumbs = () => (
    <div className="breadcrumbs">
      {breadcrumbs && breadcrumbs.map((crumb, index) => (
        <span key={crumb.id || 'home'}>
          {index > 0 && ' > '}
          <span onClick={() => handleFolderClick(crumb.id)}>{crumb.name}</span>
        </span>
      ))}
    </div>
  );


  const renderViewer = () => {
    console.log('Rendering viewer with:', { viewerUrl, viewerType });
    if (error) {
      return <div>Error: {error}</div>;
    }
    switch (viewerType) {
      case 'xlsx':
      case 'xls':
        return <XLSXViewer fileUrl={viewerUrl} />;
      case 'pdf':
      case 'docx':
      case 'doc':
      default:
        return (
          <iframe
            src={viewerUrl}
            title="Document Viewer"
            width="100%"
            height="100%"
            frameBorder="0"
            onLoad={() => console.log('iframe loaded')}
            onError={(e) => console.error('iframe error:', e)}
          />
        );
    }
  };

  const handleEdit = (document, isSaving = false) => {
    if (isSaving) {
      handleSaveEdit(document);
    } else {
      console.log('Editing document:', document);
      setEditingDocId(document.document_uuid);
      setEditedProject(document.project || '');
    }
  };

  const handleDelete = (document) => {
    // Implement delete functionality
    console.log('Deleting document:', document);
  };

  const handleView = (url) => {
    callViewFunction(url, 'pdf'); // Assuming PDF for now, adjust as needed
  };


  return (
    <DndProvider backend={HTML5Backend}>
      <div className='main-page'>
        <div className="file-explorer">
          <div className='header-text'>File Explorer</div>

          <div className="search-and-sort">
            <div className="search-bar">
              <i className="search-icon fas fa-search"></i>
              <input
                type="text"
                placeholder="Search documents..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="sort-options">
              <button className="sort-button" onClick={() => handleSort('document_name')}>
                Name <i className={`sort-icon fas fa-sort${sortField === 'document_name' ? (sortDirection === 'asc' ? '-up' : '-down') : ''}`}></i>
              </button>
              <button className="sort-button" onClick={() => handleSort('status')}>
                Status <i className={`sort-icon fas fa-sort${sortField === 'status' ? (sortDirection === 'asc' ? '-up' : '-down') : ''}`}></i>
              </button>
              <button className="sort-button" onClick={() => handleSort('document_type')}>
                Type <i className={`sort-icon fas fa-sort${sortField === 'document_type' ? (sortDirection === 'asc' ? '-up' : '-down') : ''}`}></i>
              </button>
            </div>
          </div>
          {renderBreadcrumbs()}
          {breadcrumbs.length > 1 ? (
            <div className='top-row-buttons'>
              <button className="new-folder-button" onClick={() => setIsNewFolderModalOpen(true)}>
                <AiOutlineFolderAdd className='button-folder-icon' /> New Folder
              </button>
              <button className="back-button" onClick={handleBackClick}>
                <AiOutlineArrowLeft /> Back
              </button>
            </div>
          ):(
            <button className="new-folder-button" onClick={() => setIsNewFolderModalOpen(true)}>
              <AiOutlineFolderAdd className='button-folder-icon' /> New Folder
            </button>
          )}
          <div className='main-area'>
            {isLoading ? (
              <div className='loading-spinner-container'>
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {folders.map(folder => (
                  <DraggableItem
                    key={folder.id}
                    item={folder}
                    type="folder"
                    onDrop={handleDrop}
                    onFolderClick={handleFolderClick}
                  />
                ))}
                {documents.map(document => (
                  <DraggableItem
                    key={document.document_uuid}
                    item={document}
                    type="file"
                    onDrop={handleDrop}
                    onShare={handleShare}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onView={handleView}
                    editingDocId={editingDocId}
                    editedProject={editedProject}
                    onProjectSelect={handleProjectSelect}
                    userData={userData}
                  />
                ))}
              </>
            )}
          </div>
        </div>
        {viewerUrl && (
          <div className="fullscreen-viewer">
            <div className="viewer-header">
              <button className="close-button" onClick={() => { setViewerUrl(null); setViewerType(null); setError(null); }}>
                <AiOutlineClose />
              </button>
            </div>
            {renderViewer()}
            <div>
            </div>
          </div>
        )}
        {sharingDocument && (
          <SharePopup
            isOpen={isSharePopupOpen}
            onClose={() => {
              setIsSharePopupOpen(false);
              setSharingDocument(null);
            }}
            documentToShare={sharingDocument}
            onShare={handleShareComplete}
            userData={userData}
          />
        )
        }
        {isNewFolderModalOpen && renderNewFolderModal()}
      </div >
    </DndProvider>
  );
};

export default FileExplorer;