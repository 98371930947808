import React, { useState, useEffect } from 'react';
import './admin.css';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { getIdToken } from '../services/userDataService';

const AdminPage = ({ userData }) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // fetch users
        const fetchUsers = async () => {
            console.log('fetching users for Admin Dashboard');
            const token = await getIdToken();
            // fetch users from API
            const users = await fetch(`${process.env.REACT_APP_FETCH_ADMIN_DASHBOARD}?org_id=${userData.user_data.org_id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${token}`,
                    }
                }
            );
            const usersData = await users.json();
            console.log(usersData);
            setUsers(usersData);
        }

        fetchUsers();
    }, []);


    return (
        <div className='admin-page'>
            <div className='main-panel'>
                <div className='admin-top-bar'>
                    Administrator Dashboard
                </div>
                <div className='admin-content'>
                    <div className='admin-content-header'>
                        <div className='admin-org-name'>WGD Consulting</div>
                    </div>
                    <div className='admin-metric-cards'>
                        <div className='admin-metric-card'>
                            <div className='admin-metric-card-value'>10</div>
                            <div className='admin-metric-card-title'>Users</div>
                        </div>
                        <div className='admin-metric-card'>
                            <div className='admin-metric-card-value'>10</div>
                            <div className='admin-metric-card-title'>Sessions</div>
                        </div>
                        <div className='admin-metric-card'>
                            <div className='admin-metric-card-value'>10</div>
                            <div className='admin-metric-card-title'>Messages</div>
                        </div>
                        <div className='admin-metric-card'>
                            <div className='admin-metric-card-value'>10</div>
                            <div className='admin-metric-card-title'>Files</div>
                        </div>

                    </div>
                    <div className='admin-user-list'>
                        <div className='admin-user-list-header'>
                            <div className='admin-user-list-header-item'>Username</div>
                            <div className='admin-user-list-header-item'>Email</div>
                            <div className='admin-user-list-header-item'>Roles</div>
                        </div>
                        <div className='admin-user-list-body'>
                            <div className='admin-user-list-item'>
                                <div className='admin-user-list-entry-item'>john.smith</div>
                                <div className='admin-user-list-entry-item'>john.smith@johndoe.com</div>
                                <div className='admin-user-list-entry-item'>Sugg</div>
                            </div>
                            <div className='admin-user-list-item'>
                                <div className='admin-user-list-entry-item'>jane.doe</div>
                                <div className='admin-user-list-entry-item'>jane.doe@johndoe.com</div>
                                <div className='admin-user-list-entry-item'>Sugg</div>
                            </div>
                            <div className='admin-user-list-item'>
                                <div className='admin-user-list-entry-item'>jane.doe</div>
                                <div className='admin-user-list-entry-item'>jane.doe@johndoe.com</div>
                                <div className='admin-user-list-entry-item'>Sugg</div>
                            </div>
                            <div className='admin-user-list-item'>
                                <div className='admin-user-list-entry-item'>jane.doe</div>
                                <div className='admin-user-list-entry-item'>jane.doe@johndoe.com</div>
                                <div className='admin-user-list-entry-item'>Sugg</div>
                            </div>
                            <div className='admin-user-list-item'>
                                <div className='admin-user-list-entry-item'>jane.doe</div>
                                <div className='admin-user-list-entry-item'>jane.doe@johndoe.com</div>
                                <div className='admin-user-list-entry-item'>Sugg</div>
                            </div>
                            <div className='admin-user-list-item'>
                                <div className='admin-user-list-entry-item'>jane.doe</div>
                                <div className='admin-user-list-entry-item'>jane.doe@johndoe.com</div>
                                <div className='admin-user-list-entry-item'>Sugg</div>
                            </div>
                            <div className='admin-user-list-item'>
                                <div className='admin-user-list-entry-item'>jane.doe</div>
                                <div className='admin-user-list-entry-item'>jane.doe@johndoe.com</div>
                                <div className='admin-user-list-entry-item'>Sugg</div>
                            </div>
                        </div>
                    </div>
                    <div className='admin-user-list-footer'>
                        <button className='admin-add-user-button'>
                            <AiOutlineUserAdd className='admin-add-user-icon' />
                            Add New User
                        </button>
                        <button className='admin-manage-user-groups-button'>
                            <AiOutlineUserAdd className='admin-manage-user-groups-icon' />
                            Manage User Groups
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPage;