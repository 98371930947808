import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import Chat from './pages/chat.js';
import SemanticSearch from './pages/semanticSearch.js';
import './App.css';
import Upload from './pages/upload.js';
import SessionCardsPanel from './components/sessionCards.js';
import { currentAuthenticatedUser } from './functions/authService';
import fetchMessagesForSession from './functions/fetchMessages';
import FileExplorer from './components/fileExplorer/FileExplorer.js';
import FolderView from './components/fileExplorer/folderView.js';
import EmailList from './components/emailList.js';
import UserSettings from './pages/userSettings.js';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './components/msalConfig.js';
import { fetchUserData, getCachedUserData, getUserGroups } from './services/userDataService';
import { set } from 'lodash';
import AdminPage from './pages/admin.js';
import OAuthCallbackComponent from './components/oAuthCallback.js';

Amplify.configure(awsExports);

const LoadingComponent = () => (
  <div className="loading-overlay">
    <div className="loading-spinner"></div>
    <p>Loading user data...</p>
  </div>
);

export default function App() {
  const [databaseList, setDatabaseList] = useState([]);
  const [database, setDatabase] = useState(() => {
    return localStorage.getItem('selectedDatabase') || 'test-base';
  });
  const [documentTitles, setDocumentTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState(() => {
    const savedDocs = localStorage.getItem('selectedDocuments');
    return savedDocs ? JSON.parse(savedDocs) : [];
  });

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userSessions, setUserSessions] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [messages, setMessages] = useState([]);

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    return savedTheme === 'dark';
  });
  const [userData, setUserData] = useState(null);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [userDataError, setUserDataError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const SESSION_GETTER = process.env.REACT_APP_SESSION_GETTER;
  const DOCDATABASE_GATHER_URL = process.env.REACT_APP_DOCDATABASE_GATHER_URL;

  useEffect(() => {
    const loadUserData = async () => {
      setIsUserDataLoading(true);
      try {
        const cachedData = getCachedUserData();
        const userGroups = await getUserGroups();
        
        if (cachedData) {
          cachedData.userGroups = userGroups;
          setUserData(cachedData);
          setIsAuthenticated(true);
        } else {
          const freshData = await fetchUserData();
          freshData.userGroups = userGroups;
          if (freshData) {
            setUserData(freshData);
            setIsAuthenticated(true);
          } else {
            throw new Error("Failed to fetch user data");
          }
        }
      } catch (error) {
        console.error('Error loading user data:', error);
        setUserDataError(error.message);
        if (retryCount < 3) {
          setRetryCount(prevCount => prevCount + 1);
          setTimeout(() => loadUserData(), 3000); // Retry after 3 seconds
        }
      } finally {
        setIsUserDataLoading(false);
      }
    };

    loadUserData();
  }, [retryCount]);

  const fetchSessions = async () => {
    try {
      const userId = await currentAuthenticatedUser();
      const response = await fetch(`${SESSION_GETTER}?userId=${encodeURIComponent(userId)}`);
      if (!response.ok) {
        throw new Error('Failed to fetch sessions');
      }
      const data = await response.json();
      setUserSessions(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error Fetching Sessions', error);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(DOCDATABASE_GATHER_URL);
      if (!response.ok) {
        throw new Error('Failed to fetch document titles');
      }
      const data = await response.json();
      const initialDatabaseList = Object.keys(data);
      if (initialDatabaseList.length > 0) {
        const persistedDatabase = localStorage.getItem('selectedDatabase') || initialDatabaseList[0];
        setDatabaseList(initialDatabaseList);
        setDatabase(persistedDatabase);
        setDocumentTitles(data[persistedDatabase]);
      }
    } catch (error) {
      console.error('Error Fetching Document Titles', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userData]);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleDatabaseChange = (newDatabase) => {
    localStorage.setItem('selectedDatabase', newDatabase);
    setDatabase(newDatabase);
    setSelectedDocuments([]);
    const fetchDataForNewDatabase = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(DOCDATABASE_GATHER_URL);
        if (!response.ok) {
          throw new Error('Failed to fetch document titles for new database');
        }
        const data = await response.json();
        setDocumentTitles(data[newDatabase]);
      } catch (error) {
        console.error(`Error Fetching Document Titles for ${newDatabase}`, error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDataForNewDatabase();
  };

  const handleSelectedDocumentsChange = (newSelectedDocuments) => {
    setSelectedDocuments(newSelectedDocuments);
    localStorage.setItem('selectedDocuments', JSON.stringify(newSelectedDocuments));
  };

  const handleDocumentTitlesChange = (newDocumentTitles) => {
    setDocumentTitles(newDocumentTitles);
  };

  const handleAddDatabase = (newDatabaseName) => {
    setDatabaseList([...databaseList, newDatabaseName]);
    handleDatabaseChange(newDatabaseName);
  };

  const handleCardClick = async (sessionId) => {
    const sessionMessages = await fetchMessagesForSession(sessionId);
    setSessionId(sessionId);
    setMessages(sessionMessages);
  };

  useEffect(() => {
    // Set a timeout to prevent indefinite loading
    const timeoutId = setTimeout(() => {
      if (isUserDataLoading) {
        setIsUserDataLoading(false);
        setUserDataError("Loading timeout. Please refresh the page.");
      }
    }, 30000); // 30 seconds timeout

    return () => clearTimeout(timeoutId);
  }, [isUserDataLoading]);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };
  return (
    <MsalProvider instance={msalInstance}>
      <Authenticator loginMechanisms={['username', 'email', 'phone_number']}>
        {({ signOut, user }) => (
          <Router>
            <main className='app-container'>
              {isAuthenticated && userData ? (
                <>
                  <SessionCardsPanel
                    onCardClick={handleCardClick}
                    sessions={userSessions}
                    isLoading={isLoading}
                    isCollapsed={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                    toggleDarkMode={toggleDarkMode}
                    isDarkMode={isDarkMode}
                    setIsPopupOpen={setIsPopupOpen}
                    userData={userData}
                  />
                  <Routes>
                    <Route path='/' element={<Chat filters={filters} userData={userData} />} />
                    <Route path='/search' element={<SemanticSearch userData={userData}/>} />
                    <Route path='/oauth-callback' element={<OAuthCallbackComponent userData={userData}/>} />
                    <Route path='/admin' element={<AdminPage userData={userData}/>} />
                    <Route path='/file-explorer' element={<FileExplorer userId={user.userId} userData={userData} />} />
                    <Route path='/email-connected' element={<EmailList userData={userData} />} />
                    <Route
                      path='/email-test'
                      element={
                        <MsalProvider instance={msalInstance}>
                          <EmailList />
                        </MsalProvider>
                      }
                    />
                    <Route 
                    path='/user-settings'
                    element={
                      <UserSettings
                      userData={userData}
                      />
                    }
                    />
                    <Route
                      path='/upload'
                      element={
                        <Upload
                          databaseList={databaseList}
                          handleDatabaseChange={handleDatabaseChange}
                          handleAddDatabase={handleAddDatabase}
                          database={database}
                          userId={user.userId}
                          userData={userData}
                        />
                      }
                    />
                    <Route path='/folder/:source/*' element={<FolderView />} />
                  </Routes>
                </>
              ) : isUserDataLoading ? (
                <LoadingComponent />
              ) : (
                <div>
                  <LoadingComponent />
                </div>
              )}
            </main>
          </Router>
        )}
      </Authenticator>
    </MsalProvider>
  );
}
