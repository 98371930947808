import { fetchAuthSession } from 'aws-amplify/auth';
import { currentAuthenticatedUser } from '../functions/authService';

const USER_DATA_KEY = 'userData';

export const getIdToken = async () => {
  try {
    const session = await fetchAuthSession();
    if (!session.tokens?.idToken) {
      throw new Error('No ID token found in session');
    }
    return session.tokens.idToken.toString();
  } catch (error) {
    console.error('Error getting ID token:', error);
    throw error;
  }
};

export const getUserGroups = async () => {
  try {
    const session = await fetchAuthSession();
    if (!session.tokens?.accessToken) {
      throw new Error('No Access token found in session');
    }
    const accessToken = session.tokens.accessToken.toString();
    const payload = JSON.parse(atob(accessToken.split('.')[1]));
    return payload['cognito:groups'] || [];
  } catch (error) {
    console.error('Error getting user groups:', error);
    return [];
  }
};

export const fetchUserData = async () => {
  try {
    const user = await currentAuthenticatedUser();
    const idToken = await getIdToken();
    const userGroups = await getUserGroups();
    
    const response = await fetch(`${process.env.REACT_APP_FETCH_USER_DATA}?user_id=${encodeURIComponent(user)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': idToken,
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }

    const userData = await response.json();
    const completeUserData = { ...userData, userGroups };
    sessionStorage.setItem(USER_DATA_KEY, JSON.stringify(completeUserData));
    return completeUserData;
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};

export const getCachedUserData = (includeGroups = true) => {
  const cachedData = sessionStorage.getItem(USER_DATA_KEY);
  if (!cachedData) return null;
  
  const parsedData = JSON.parse(cachedData);
  if (!includeGroups) {
    const { userGroups, ...dataWithoutGroups } = parsedData;
    return dataWithoutGroups;
  }
  return parsedData;
};

export const clearUserData = () => {
  sessionStorage.removeItem(USER_DATA_KEY);
};