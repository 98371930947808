import { PublicClientApplication } from "@azure/msal-browser";
import process from "process";

export const msalConfig = {
  auth: {
    clientId: 'f22a28b7-d656-4a1d-a9d1-a34aecd2e9c6',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read", "Files.Read", "Sites.Read.All", "Files.Read.All"]
};


export const msalInstance = new PublicClientApplication(msalConfig);