import React, { useState, useEffect, useRef } from 'react';
import './chatHistory.css';
import CustomHamburgerIcon from './assets/customHamburgerIcon';
import { AiOutlineEdit } from 'react-icons/ai';
import LoadingSpinner from './assets/loadingSpinner';


// Function to categorize sessions into 'yesterday', 'this week', and 'by month and year'
const categorizeSessions = (sessions) => {
  if (!sessions) return { yesterday: [], thisWeek: [], byMonthAndYear: {} };

  const today_list = [];
  const yesterday = [];
  const thisWeek = [];
  const byMonthAndYear = {};

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const oneDay = 24 * 60 * 60 * 1000;
  const twoDay = 2 * oneDay;
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay());

  sessions.forEach((session) => {
    const sessionDate = new Date(session.timestamp);
    const sessionDay = new Date(sessionDate.getFullYear(), sessionDate.getMonth(), sessionDate.getDate());

    const timeDifference = today - sessionDay;

    if (timeDifference <= oneDay) {
      today_list.push(session);
    } else if (timeDifference <= twoDay) {
      yesterday.push(session);
    } else if (sessionDay >= startOfWeek) {
      thisWeek.push(session);
    } else {
      const monthYear = `${sessionDate.toLocaleString('default', { month: 'long' })} ${sessionDate.getFullYear()}`;
      if (!byMonthAndYear[monthYear]) {
        byMonthAndYear[monthYear] = [];
      }
      byMonthAndYear[monthYear].push(session);
    }
  });

  return { today_list, yesterday, thisWeek, byMonthAndYear };
};

const ChatHistoryPanel = ({ history, onCardClick, onRename, onLoadMore, isLoading, hasMore, isOpen,userData }) => {
  const [visibleItems, setVisibleItems] = useState(15); // Visible items state
  const [editingSessionId, setEditingSessionId] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  const [animationTriggered, setAnimationTriggered] = useState(false);

  const { today_list, yesterday, thisWeek, byMonthAndYear } = categorizeSessions(history); // Categorize sessions

  const observer = useRef();
  const lastSessionElementRef = useRef();

  useEffect(() => {
    if (isOpen) {
      // Slight delay to ensure panel is visible before triggering tile animation
      setTimeout(() => setAnimationTriggered(true), 50);
    } else {
      setAnimationTriggered(false);
    }
  }, [isOpen]);

  useEffect(() => {
    console.log('History:', history);
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        onLoadMore();
      }
    });
    if (lastSessionElementRef.current) {
      observer.current.observe(lastSessionElementRef.current);
    }
  }, [isLoading, hasMore]);

  // Handle scroll event to load more items
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setVisibleItems(prevVisibleItems => prevVisibleItems + 15);
    }
  };

  // Attach scroll event listener
  useEffect(() => {
    const panel = document.querySelector('.history-content');
    panel.addEventListener('scroll', handleScroll);
    return () => panel.removeEventListener('scroll', handleScroll);
  }, []);

  const handleRenameClick = (sessionId, currentTitle) => {
    setEditingSessionId(sessionId);
    setNewTitle(currentTitle);
  };

  const handleRenameSubmit = (sessionId) => {
    if (newTitle.trim() !== '') {
      onRename(sessionId, newTitle.trim());
      setEditingSessionId(null);
      setNewTitle('');
    }
  };

  // Render session items
  const renderSessions = (sessions) => {
    return sessions.map((session, index) => (
      <li 
        key={session.sessionId} 
        className={animationTriggered ? 'animate' : ''}
        style={{ transitionDelay: `${0.15 * index}s` }}  // Apply delay based on index
      >
        {editingSessionId === session.sessionId ? (
          <form onSubmit={(e) => { e.preventDefault(); handleRenameSubmit(session.sessionId); }}>
            <input
              className='rename-input'
              type="text"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              autoFocus
            />
            <button type="submit" className='submit-change-name'>Save</button>
          </form>
        ) : (
          <div className="session-content" onClick={() => onCardClick(session.sessionId)}>
            <div className="session-id">
              {session.title || 'Untitled Chat'}
              <AiOutlineEdit size={24} className='rename-icon' onClick={(e) => { e.stopPropagation(); handleRenameClick(session.sessionId, session.title || 'Untitled Chat'); }} />
            </div>
            <div className="timestamp">Created at: {new Date(session.timestamp).toLocaleString()}</div>
          </div>
        )}
      </li>
    ));
  };

  return (
    <div className={`chat-history-panel ${isOpen ? 'open' : ''}`}>
      <div className="history-content">
        <h3>Previous Chats</h3>
        {isLoading && history.length === 0 ? (
          <div className="loading-spinner-container">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <ul>
              {today_list.length > 0 && (
                <>
                  <h4>Today</h4>
                  {renderSessions(today_list)}
                </>
              )}
              {yesterday.length > 0 && (
                <>
                  <h4>Yesterday</h4>
                  {renderSessions(yesterday)}
                </>
              )}
              {thisWeek.length > 0 && (
                <>
                  <h4>This Week</h4>
                  {renderSessions(thisWeek)}
                </>
              )}
              {Object.keys(byMonthAndYear).map((monthYear) => (
                <React.Fragment key={monthYear}>
                  <h4>{monthYear}</h4>
                  {renderSessions(byMonthAndYear[monthYear])}
                </React.Fragment>
              ))}
            </ul>
            {isLoading && <LoadingSpinner />}
            {!isLoading && hasMore && (
              <button onClick={onLoadMore} className="load-more-button">
                Load More
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChatHistoryPanel;
