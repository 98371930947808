import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getIdToken } from '../services/userDataService';

const ProjectSelector = ({ userData, onProjectSelect }) => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [newProject, setNewProject] = useState('');
    const [isAddingNew, setIsAddingNew] = useState(false);

    const GET_PROJECTS_URL = process.env.REACT_APP_GET_PROJECTS_URL;
    const CREATE_PROJECT_URL = process.env.REACT_APP_CREATE_PROJECT_URL;

    useEffect(() => {
        fetchProjects();
    }, [userData.user_data.org_id]);

    const fetchProjects = async () => {
        try {
            const response = await axios.post(
                GET_PROJECTS_URL,
                { 'org_id': userData.user_data.org_id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getIdToken(),
                    }
                });
            setProjects(response.data.projects);
        } catch (error) {
            console.error('Failed to fetch projects:', error);
        }
    };

    const handleProjectChange = (e) => {
        const value = e.target.value;
        if (value === 'add_new') {
            setIsAddingNew(true);
        } else {
            setSelectedProject(value);
            onProjectSelect(value);
        }
    };

    const handleAddNewProject = async () => {
        if (!newProject.trim()) return;
        try {
            const response = await axios.post(CREATE_PROJECT_URL,
                { name: newProject, org_id: userData.user_data.org_id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getIdToken(),
                    }
                });
            const addedProject = response.data;
            // Ensure the new project has the correct structure
            const newProjectItem = {
                project_id: addedProject.project_id || addedProject.id,
                name: addedProject.name
            };
            setProjects(prevProjects => [...prevProjects, newProjectItem]);
            setSelectedProject(newProjectItem.project_id);
            onProjectSelect(newProjectItem.project_id);
            setIsAddingNew(false);
            setNewProject('');
        } catch (error) {
            console.error('Failed to add new project:', error);
        }
    };

    return (
        <div style={{ margin: '10px 0' }}>
            {isAddingNew ? (
                <div>
                    <input
                        type="text"
                        value={newProject}
                        onChange={(e) => setNewProject(e.target.value)}
                        placeholder="Enter new project name"
                        style={{ marginRight: '5px' }}
                    />
                    <button onClick={handleAddNewProject} style={{ marginRight: '5px' }}>Add Project</button>
                    <button onClick={() => setIsAddingNew(false)}>Cancel</button>
                </div>
            ) : (
                <select
                    value={selectedProject}
                    onChange={handleProjectChange}
                    style={{ padding: '5px', minWidth: '200px' }}
                >
                    <option value="">Select Project</option>
                    {projects.map(project => (
                        <option key={project.project_id} value={project.project_id}>
                            {project.name}
                        </option>
                    ))}
                    <option value="add_new">+ Add New Project</option>
                </select>
            )}
        </div>
    );
};

export default ProjectSelector;