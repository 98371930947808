import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { AiOutlineHome, AiOutlineSearch, AiOutlineCloudUpload, AiOutlineFolder, AiOutlineSun, AiOutlineMoon, AiOutlineMail, AiOutlineUser, AiOutlineSetting, AiOutlineLock } from 'react-icons/ai';
import { currentAuthenticatedUser } from '../functions/authService';
import './sessionCards.css';

const SessionCardsPanel = ({ logDatabases, logDocs, toggleDarkMode, isDarkMode, setIsPopupOpen, userData }) => {
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (userData) {
            setUser(userData);
            setIsLoading(false);
        }
    }, [userData]);

    const isActive = (path) => {
        return location.pathname === path ? 'selected' : '';
    };

    return (
        <div className="main-container">
            <div className="side-menu">
                <div className="user-info">
                    <AiOutlineUser className="user-icon" />
                    <span className="user-name">{userData?.user_data.name || 'User'}</span>
                </div>
                <span className="company">{userData?.org_data.name || 'User'}</span>
                <div className="nav-items">
                    <div className="menu-container">
                    </div>
                    <Link className={`menu-item ${isActive('/')}`} to='/'>
                        <AiOutlineHome className={`icon ${isActive('/')}`} />
                        <span className={`icon-text ${isActive('/')}`}>Home</span>
                    </Link>
                    <Link className={`menu-item ${isActive('/search')}`} to='/search'>
                        <AiOutlineSearch className={`icon ${isActive('/search')}`} />
                        <span className={`icon-text ${isActive('/search')}`}>Search</span>
                    </Link>
                    <Link className={`menu-item ${isActive('/upload')}`} to='/upload'>
                        <AiOutlineCloudUpload className={`icon ${isActive('/upload')}`} />
                        <span className={`icon-text ${isActive('/upload')}`}>Upload</span>
                    </Link>
                    <Link className={`menu-item ${isActive('/file-explorer')}`} to='/file-explorer'>
                        <AiOutlineFolder className={`icon ${isActive('/file-explorer')}`} />
                        <span className={`icon-text ${isActive('/file-explorer')}`}>Files</span>
                    </Link>
                    <Link className={`menu-item ${isActive('/user-settings')}`} to='/user-settings'>
                        <AiOutlineSetting className={`icon ${isActive('/user-settings')}`} />
                        <span className={`icon-text ${isActive('/user-settings')}`}>User Settings</span>
                    </Link>
                    {userData?.userGroups?.includes('Admin') && (
                        <Link className={`menu-item ${isActive('/admin')}`} to='/admin'>
                            <AiOutlineLock className={`icon ${isActive('/admin')}`} />
                            <span className={`icon-text ${isActive('/admin')}`}>Admin</span>
                        </Link>
                    )}
                    {/* <Link className={`menu-item ${isActive('/email-test')}`} to='/email-test'>
                        <AiOutlineMail className={`icon ${isActive('/email-test')}`} />
                        <span className={`icon-text ${isActive('/email-test')}`}>Emails</span>
                    </Link> */}
                    <div className="menu-item" onClick={toggleDarkMode}>
                        {isDarkMode ? <AiOutlineSun className="icon" /> : <AiOutlineMoon className="icon" />}
                        {isDarkMode? <span className="icon-text">Light Mode</span> : <span className="icon-text">Dark Mode</span>}
                    </div>
                </div>
                <div className="info-box">
                    <div className="text-box">
                        <p>If you have any questions or concerns, or would like to request a new feature, please click to contact support</p>
                    </div>
                    <div className="contact-button">
                        <p>Contact Us</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionCardsPanel;