import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import './fileCard.css';
import ProjectSelect from './projectSelector';

const FileCard = ({ fileObj, index, updateMetadata, addCustomField, updateCustomField, removeFile, removeCustomField, userData }) => {
    const [expanded, setExpanded] = useState(false);

    const file = fileObj.file;

    if (!file) {
        console.error('File object is undefined:', fileObj);
        return null;
    }

    const toggleExpand = () => setExpanded(!expanded);

    const handleProjectChange = (e) => {
        if (e.target.value === 'add_new') {
            const projectName = prompt('Enter new project name:');
            if (projectName) {
                updateMetadata(index, 'project', projectName);
            }
        } else {
            updateMetadata(index, 'project', e.target.value);
        }
    };

    return (
        <div className={`file-card ${expanded ? 'expanded' : ''}`}>
            <div className="file-card-header">
                <button className="remove-file" onClick={() => removeFile(index)}>
                    <AiOutlineClose />
                </button>
                <div className="file-card-name">{file.name}</div>
                <div className="file-card-actions">
                    <button className="details-toggle" onClick={toggleExpand}>
                        {expanded ? <AiOutlineUp /> : <AiOutlineDown />} Details
                    </button>
                </div>
            </div>
            {expanded && (
                <div className="file-card-content">
                    <div className="input-fields">
                        <input
                            type="text"
                            placeholder="Keywords"
                            value={fileObj.metadata.keywords || ''}
                            onChange={(e) => updateMetadata(index, 'keywords', e.target.value)}
                        />
                        <ProjectSelect 
                        userData={userData}
                        onProjectSelect={(project) => updateMetadata(index, 'project', project)}
                        />
                        {/* <select
                            value={fileObj.metadata.project || ''}
                            onChange={handleProjectChange}
                        >
                            <option value="">Select Project</option>
                            {projects.map((project, idx) => (
                                <option key={idx} value={project}>{project}</option>
                            ))}
                            <option value="add_new">+ Add New Project</option>
                        </select> */}
                        <select
                            value={fileObj.metadata.permissions || ''}
                            onChange={(e) => updateMetadata(index, 'permissions', e.target.value)}
                        >
                            <option value="">Select Permissions</option>
                            <option value="public">Public</option>
                            <option value="private">Private</option>
                            <option value="team">Team</option>
                        </select>
                        {fileObj.customFields.map((field, fieldIndex) => (
                            <div key={fieldIndex} className="custom-field">
                                <input
                                    type="text"
                                    placeholder="Field name"
                                    value={field.name}
                                    onChange={(e) => updateCustomField(index, fieldIndex, 'name', e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Field value"
                                    value={field.value}
                                    onChange={(e) => updateCustomField(index, fieldIndex, 'value', e.target.value)}
                                />
                                <button className="remove-custom-field" onClick={() => removeCustomField(index, fieldIndex)}>
                                    <AiOutlineClose />
                                </button>
                            </div>
                        ))}
                        <button className="add-custom-field" onClick={() => addCustomField(index)}>Add Custom Field</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileCard;