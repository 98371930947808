import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getIdToken } from '../services/userDataService';

const OAuthCallbackComponent = (userData) => {
    const [status, setStatus] = useState('Processing...');
    const navigate = useNavigate();
    const location = useLocation();

    const OAUTH_EMAIL_HANDLER = process.env.REACT_APP_OAUTH_EMAIL_HANDLER;
    
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');
        const error = urlParams.get('error');

        console.log('code:', code);

        if (error) {
            setStatus('Authentication failed');
            // Redirect to error page or show error message
            return;
        }

        if (code) {
            exchangeCodeForToken(code);
        }
    }, [location]);

    const exchangeCodeForToken = async (code) => {
        try {
            console.log('Exchanging Code for Token');
            console.log('User Data:', userData);
            const response = await fetch(OAUTH_EMAIL_HANDLER, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getIdToken()
                },
                body: JSON.stringify({
                    action: 'exchange_code',
                    code: code,
                    provider: 'gmail', // or 'outlook', you might want to pass this as a parameter
                    user_id: userData.userData.user_data.user_id // You should replace this with the actual user ID
                }),
            });

            const data = await response.json();
            console.log(data);

            if (response.ok) {
                setStatus('Authentication successful');
                // Redirect to success page or show success message
                navigate('/email-connected');
            } else {
                setStatus('Token exchange failed');
                // Handle error
            }
        } catch (error) {
            setStatus('An error occurred');
            // Handle error
        }
    };

    return (
        <div>
            <h2>OAuth Callback</h2>
            <p>{status}</p>
        </div>
    );
};

export default OAuthCallbackComponent;