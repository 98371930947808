import React, { useState, useMemo, useCallback } from 'react';
import { IoIosArrowDown, IoIosArrowUp, IoMdClose } from 'react-icons/io';
import debounce from 'lodash/debounce';
import Fuse from 'fuse.js';
import './filterPanel.css';

const highlightMatch = (text, query) => {
  if (!query.trim()) return text;
  const regex = new RegExp(`(${query.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')})`, 'gi');
  return text.replace(regex, '<mark>$1</mark>');
};

const FilterPopup = ({ isOpen, onClose, title, options, selectedOptions, onChange, allSelected }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const fuse = useMemo(() => new Fuse(options, {
    keys: ['label'],
    threshold: 0.4,
    ignoreLocation: true,
  }), [options]);

  const debouncedSearch = useCallback(
    debounce((value) => setDebouncedSearchTerm(value), 300),
    []
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleOptionToggle = (value) => {
    const newSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter(item => item !== value)
      : [...selectedOptions, value];
    onChange(newSelectedOptions);
  };

  const filteredOptions = useMemo(() => {
    if (!debouncedSearchTerm.trim()) return { exact: options, fuzzy: [] };

    const exactMatches = options.filter(option =>
      option.label.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );

    const fuzzyResults = fuse.search(debouncedSearchTerm)
      .filter(result => !exactMatches.some(exact => exact.value === result.item.value));

    return {
      exact: exactMatches.map(option => ({
        ...option,
        highlighted: highlightMatch(option.label, debouncedSearchTerm),
      })),
      fuzzy: fuzzyResults.map(result => ({
        ...result.item,
        highlighted: highlightMatch(result.item.label, debouncedSearchTerm),
      })),
    };
  }, [options, debouncedSearchTerm, fuse]);

  if (!isOpen) return null;

  return (
    <div className="filter-popup-overlay" onClick={onClose}>
      <div className="filter-popup" onClick={(e) => e.stopPropagation()}>
        <div className="filter-popup-header">
          <h2>{title}</h2>
          <button onClick={onClose}><IoMdClose /></button>
        </div>
        <div className="filter-popup-content">
          {title === 'Documents' && (
            <label className="filter-option-label">
              <input
                type="checkbox"
                checked={allSelected}
                onChange={() => onChange([])}
              />
              <span>All Documents</span>
            </label>
          )}
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="filter-search-input"
          />
          {filteredOptions.exact.length > 0 || filteredOptions.fuzzy.length > 0 ? (
            <>
              {filteredOptions.exact.map(option => (
                <label key={option.value} className="filter-option-label">
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option.value)}
                    onChange={() => handleOptionToggle(option.value)}
                  />
                  <span dangerouslySetInnerHTML={{ __html: option.highlighted || option.label }} />
                </label>
              ))}
              {filteredOptions.fuzzy.length > 0 && (
                <>
                  <div className="similar-divider">Similar</div>
                  {filteredOptions.fuzzy.map(option => (
                    <label key={option.value} className="filter-option-label">
                      <input
                        type="checkbox"
                        checked={selectedOptions.includes(option.value)}
                        onChange={() => handleOptionToggle(option.value)}
                      />
                      <span dangerouslySetInnerHTML={{ __html: option.highlighted || option.label }} />
                    </label>
                  ))}
                </>
              )}
            </>
          ) : (
            <div className="no-results">No matching documents found</div>
          )}
        </div>
        <div className="filter-popup-footer">
          <button className="filter-popup-button clear" onClick={() => onChange([])}>Clear</button>
          <button className="filter-popup-button apply" onClick={onClose}>Apply</button>
        </div>
      </div>
    </div>
  );
};


const FilterBar = ({ filters, onFilterChange, documents, projects }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activePopup, setActivePopup] = useState(null);
  const [customInstructions, setCustomInstructions] = useState('');
  const [localFilters, setLocalFilters] = useState(filters);


  const toggleExpand = () => setIsExpanded(!isExpanded);

  const openPopup = (filterName) => setActivePopup(filterName);
  const closePopup = () => setActivePopup(null);

  const handleFilterChange = (filterName, newSelectedOptions) => {
    let newFilters;
    if (filterName === 'documents') {
      // If no documents are selected, it means "All Documents"
      const allDocumentsSelected = newSelectedOptions.length === 0;
      newFilters = {
        ...filters,
        documents: allDocumentsSelected ? [] : newSelectedOptions,
        allDocumentsSelected
      };
    } else {
      newFilters = {
        ...filters,
        [filterName]: newSelectedOptions
      };
    }
    onFilterChange(newFilters);
  };

  const handleCustomInstructionsChange = (e) => {
    setCustomInstructions(e.target.value);
  };

  const applyFilters = () => {
    const finalFilters = { ...localFilters, customInstructions };

    // If no documents are specifically selected, use all documents
    if (finalFilters.documents.length === 0) {
      finalFilters.documents = documents.map(doc => doc.document_name);
    }

    onFilterChange(finalFilters);
  };

  const clearFilters = () => {
    const clearedFilters = {
      documents: [],
      project: [],
      fileType: [],
      keywords: [],
      uploadedOn: [],
      customInstructions: ''
    };
    setLocalFilters(clearedFilters);
    setCustomInstructions('');
    onFilterChange(clearedFilters);
  };

  const filterOptions = {
    documents: documents.map(doc => ({ value: doc.document_name, label: doc.document_name })),
    project: projects.map(project => ({ value: project.project_id, label: project.name })),
    fileType: [
      { value: 'pdf', label: 'PDF' },
      { value: 'xlsx', label: 'XLSX' },
      { value: 'docx', label: 'DOCX' }
    ],
    keywords: [
      { value: 'keyword1', label: 'Keyword 1' },
      { value: 'keyword2', label: 'Keyword 2' }
    ],
    uploadedOn: [
      { value: 'today', label: 'Today' },
      { value: 'thisWeek', label: 'This Week' },
      { value: 'thisMonth', label: 'This Month' }
    ]
  };

  return (
    <>
      <div className="filter-bar">
        <div className='top-bar' onClick={toggleExpand}>
          <span className='filter-label'>Filters</span>
          {isExpanded ? <IoIosArrowUp className='filters-logo' /> : <IoIosArrowDown className='filters-logo' />}
        </div>

        {isExpanded && (
          <div className="expanded-content">
            <div className="filters-grid">
              {Object.entries(filterOptions).map(([key, options]) => (
                <button key={key} onClick={() => openPopup(key)} className="filter-button">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                  {filters[key].length > 0 && <span className="filter-count">({filters[key].length})</span>}
                </button>
              ))}
            </div>
            <div className="instructions-column">
              <div className='custom-instructions-header'>Custom Instructions</div>
              <textarea
                placeholder="Enter custom instructions here..."
                value={customInstructions}
                onChange={handleCustomInstructionsChange}
              />
              <div className='custom-instructions-buttons'>
                <button onClick={applyFilters} className='apply-button'>Apply</button>
                <button onClick={clearFilters} className='clear-button'>Clear</button>
              </div>
            </div>
          </div>
        )}
      </div>

      {Object.entries(filterOptions).map(([key, options]) => (
        <FilterPopup
          key={key}
          isOpen={activePopup === key}
          onClose={closePopup}
          title={key.charAt(0).toUpperCase() + key.slice(1)}
          options={options}
          selectedOptions={key === 'documents' && filters.allDocumentsSelected ? [] : filters[key]}
          onChange={(newSelectedOptions) => handleFilterChange(key, newSelectedOptions)}
          allSelected={key === 'documents' && filters.allDocumentsSelected}
        />
      ))}
    </>
  );
};

export default FilterBar;