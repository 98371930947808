import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeft, FaHouseUser } from 'react-icons/fa';
import './folderView.css';
import { AiOutlineFilePdf, AiOutlineFolder } from 'react-icons/ai';

// Fetch files from the API
const fetchFiles = async (source, path = '') => {
  try {
    const response = await fetch(`https://llpzm4cmuewyyssm6h7zdkqa2e0okitv.lambda-url.ca-central-1.on.aws/?source=${source}&parent_folder=${path}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching ${source} files:`, error);
    return [];
  }
};

const FolderView = () => {
  const { source, path = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [folder, setFolder] = useState();
  const [files, setFiles] = useState([]);
  const [currentPath, setCurrentPath] = useState(path);
  const [fullPath, setFullPath] = useState(source);
  const [linkHistory, setLinkHistory] = useState({});


  useEffect(() => {
    const initializeFiles = async () => {
      const pathFromUrl = location.pathname.split('/').slice(3).join('/');
      setCurrentPath(pathFromUrl);
      const fetchedFiles = await fetchFiles(source, pathFromUrl);
      const usefulFiles = fetchedFiles.filter(file => file.document_type.includes('folder') || file.document_type.includes('pdf'));
      setFiles(usefulFiles);
      if (location.pathname.split('/').slice(3).join('/') in linkHistory) {
        setFullPath(linkHistory[location.pathname.split('/').slice(3).join('/')]);
        return;
      } else if (folder) {
        setFullPath(fullPath + '/' + folder.document_name);
      }
    };
    initializeFiles();
  }, [source, location.pathname]);

  useEffect(() => { 
    setLinkHistory({...linkHistory, [currentPath]: fullPath});
  }, [fullPath]);


  const handleFolderClick = (file) => {
    let newPath;
    setFolder(file);
    if (source === 'google_drive') {
      newPath = file.url.split('/').slice(-2)[0];
      setCurrentPath(newPath);
    } else if (source === 'onedrive') {
      newPath = file.url.split('/').slice(-1)[0];
      setCurrentPath(newPath);
    }

    navigate(`/folder/${source}/${newPath}`);
  };

  const handleBackClick = () => {
    const pathParts = currentPath.split('/');
    pathParts.pop(); // Remove the last part to go up one level
    const newPath = pathParts.join('/');
    navigate(`/folder/${source}/${newPath}`);
  };

  return (
    <div className='folder-page'>
      <div className="folder-view">
        <div className='buttons'>
        <button onClick={handleBackClick} className="back-button">
          <FaArrowLeft className="back-icon" />
          Back
        </button>
        <Link to="/file-explorer" className='home'>
        <FaHouseUser className='home-icon'/>
        Back to File Explorer
        </Link>
        </div>
        <div className="path-display">
          Argus://{fullPath}
        </div>
        <div className="file-window">
          {files.map(file => (
            <div key={file.document_id} className='file' onClick={() => file.document_type.includes('folder') && handleFolderClick(file)}>
              <div className={`file-icon`} >{file.document_type.includes('folder') ? <AiOutlineFolder/> : <AiOutlineFilePdf/>}</div> 
              <div className='file-name'>{file.document_name.split('.')[0]}</div>
              <div className='right-side-buttons'>
                {file.document_type.includes('pdf') && (
                  <>
                  <a href={file.url} target="_blank" rel="noreferrer">
                    <button className='open-button'>View</button>
                  </a>
                <div className={`indexed-status ${file.status}`}>
                  {file.status === 'not_indexed' ? 'Not Indexed' : 'Indexed'}
                </div>
                </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FolderView;