// src/functions/authService.js
import { getCurrentUser } from 'aws-amplify/auth';

export async function currentAuthenticatedUser() {
  try {
    const { username, userId, signInDetails } = await getCurrentUser();
    // console.log(`The username: ${username}`);
    // console.log(`The userId: ${userId}`);
    // console.log(`The signInDetails: ${signInDetails}`);

    return userId;
  } catch (err) {
    console.log(err);
  }
}